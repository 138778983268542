import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListAudience(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_audience', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAudience(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_audience', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAudience(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_audience', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAudience(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_audience', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAudience(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_audience/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultAudience(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_audience', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListAudienceSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_audience_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
