<template>
  <b-modal
    id="form-audience"
    :visible="shallShowAudienceFormModal"
    :title="`${$t('Form')} ${$t('Audience')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-audience-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeAudienceForm)} ${$t('Audience')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-audience-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeAudienceForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col cols="6">

                    <b-row>
                      <!-- Field: Select Audience Type -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="typeAudienceForm === 'Add'"
                          :label="`${$t('Select Audience Type')}`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            :variant="(audienceData.type === 'specialty')? 'info' : 'outline-info'"
                            @click="onSelectAudienceType('specialty')"
                          >
                            Specialty
                          </b-button>
                          <b-button
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            :variant="(audienceData.type === 'line_user_id')? 'info' : 'outline-info'"
                            @click="onSelectAudienceType('line_user_id')"
                          >
                            LINE User ID
                          </b-button>
                        </b-form-group>

                        <b-form-group
                          v-if="typeAudienceForm === 'Edit'"
                          :label="`${$t('Select Message Type')} (${$t('Audience')})`"
                          style="margin-bottom: 0.5rem;"
                        >
                          <b-button
                            v-model="audienceData.type"
                            style="margin-bottom: 0.5rem; margin-right: 0.8rem;"
                            size="sm"
                            variant="info"
                          >
                            {{ (audienceData.type === 'specialty')? 'Specialty' : '' }}
                            {{ (audienceData.type === 'line_user_id')? 'LINE User ID' : '' }}
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeAudienceForm === 'Add' || typeAudienceForm === 'Edit')"
                          :label="$t('Name')"
                          label-for="audience-name"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Name')"
                            rules="required"
                          >
                            <b-form-input
                              id="audience-name"
                              v-model="audienceData.name"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Name')"
                          label-for="audience-name"
                        >
                          <span class="form-info-box">{{ audienceData.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Status -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeAudienceForm === 'Add' || typeAudienceForm === 'Edit')"
                          :label="$t('Status')"
                          label-for="audience-status"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Status')"
                            rules="required"
                          >
                            <b-form-radio
                              v-model="audienceData.status"
                              name="audience-status"
                              value="active"
                              class="custom-control-success cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Active') }}</span>
                            </b-form-radio>
                            <b-form-radio
                              v-model="audienceData.status"
                              name="audience-status"
                              value="inactive"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                            </b-form-radio>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Status')"
                          label-for="audience-status"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="`light-${isStatusVariant(audienceData.status)}`"
                              class="text-capitalize"
                            >
                              {{ $t(textFirstUpper(audienceData.status)) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                  <b-col cols="6">
                    <!-- Data -->
                    <b-row v-if="audienceData.type === 'specialty'">
                      <!-- Field: Specialty -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeAudienceForm === 'Add' || typeAudienceForm === 'Edit')"
                          :label="$t('Specialty')"
                          label-for="broadcast-specialty"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Specialty')"
                            rules="required"
                          >
                            <v-select
                              v-model="audienceData.specialty"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="audienceData.specialty_options"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="broadcast-specialty"
                              multiple
                              chips
                              :state="getValidationState(validationContext)"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Specialty')"
                          label-for="broadcast-specialty"
                        >
                          <span class="form-info-box">
                            <b-badge
                              v-for="name in audienceData.specialty_name"
                              :key="name"
                              pill
                              :variant="(skin === 'dark')? `light-primary` : `primary` "
                              class="text-capitalize"
                              style="margin-right: 4px;"
                            >
                              {{ name }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row v-if="audienceData.type === 'line_user_id'">
                      <b-col>
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <b-form-group
                              v-if="(typeAudienceForm === 'Add' || typeAudienceForm === 'Edit')"
                              :label="$t('Choose Excel file for import')"
                              label-for="audience-import"
                            >
                              <b-form-file
                                id="audience-import"
                                ref="fileImport"
                                :placeholder="$t('Choose Excel file for import')"
                                size="lg"
                                accept=".xlsx, .xls"
                                no-drop
                                :browse-text="$t('Browse File')"
                                @change="changeFileImport"
                              >
                                <template
                                  slot="file-name"
                                  slot-scope="{ names }"
                                >
                                  <b-badge variant="success">
                                    {{ names[0] }}
                                  </b-badge>
                                  <b-badge
                                    v-if="names.length > 1"
                                    variant="dark"
                                    class="ml-1"
                                  >
                                    + {{ names.length - 1 }} More files
                                  </b-badge>
                                </template>
                              </b-form-file>

                              <b-alert
                                v-if="sheet !== ''"
                                show
                                fade
                                style="margin-top: 0.6rem;"
                                variant="success"
                              >
                                <div class="alert-body">
                                  <feather-icon
                                    icon="InfoIcon"
                                    class="mr-50"
                                  />
                                  <span>
                                    {{ $t('Sheet Name') }}: {{ sheet }}
                                  </span>
                                </div>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row style="margin-top: 0rem;">
                      <!-- Field: JSON Data -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeAudienceForm === 'Add' || typeAudienceForm === 'Edit')"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="`${$t('JSON Data')}`"
                            rules="required"
                          >
                            <b-row>
                              <b-col
                                cols="12"
                                class="d-flex align-items-center justify-content-start"
                              >
                                <span>{{ `${$t('JSON Data')}` }}</span>
                              </b-col>
                            </b-row>
                            <v-jsoneditor
                              v-model="audienceData.json"
                              :options="optionsAudienceData"
                              :plus="false"
                              height="300px"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="`${$t('JSON Data')}`"
                          label-for="audience-json"
                        >
                          <v-jsoneditor
                            v-model="audienceData.json"
                            :options="optionsViewData"
                            :plus="false"
                            height="300px"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BFormFile,
  BBadge,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx'
import VJsoneditor from 'v-jsoneditor'

export default {
  directives: {
    Ripple,
  },
  components: {

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BFormFile,
    BBadge,
    BAlert,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    VJsoneditor,
  },
  model: {
    prop: 'shallShowAudienceFormModal',
    event: 'update:shall-show-audience-form-modal',
  },
  props: {
    shallShowAudienceFormModal: {
      type: Boolean,
      required: true,
    },
    typeAudienceForm: {
      type: String,
      default: 'Add',
    },
    audienceData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      rawFileImport: null,
      sheetOptions: [],
      sheetName: '',
      sheet: '',
      excelData: {
        header: null,
        results: null,
        meta: null,
      },
      importCountTotal: 0,
      importCountOk: 0,
      importCountError: 0,
      optionsAudienceData: {
        mode: 'code',
        mainMenuBar: true,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      optionsViewData: {
        mode: 'code',
        mainMenuBar: false,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
    }
  },
  watch: {
    audienceData() {
      if (this.typeAudienceForm === 'Add') {
        this.defaultName()
      }
    },
    // eslint-disable-next-line func-names
    'audienceData.specialty': function (newValue) {
      // console.log(newValue)
      if (newValue !== undefined && newValue !== '') {
        this.audienceData.json = newValue
      }
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    isStatusVariant(isstatus) {
      if (isstatus === 'active') return 'success'
      if (isstatus === 'inactive') return 'danger'
      return 'danger'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeAudienceForm === 'Add') {
        store.dispatch('audience-store/addAudience', this.audienceData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-audience-form')
            this.$emit('update:shall-show-audience-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeAudienceForm === 'Edit') {
        store.dispatch('audience-store/editAudience', { id: this.audienceData.id, data: this.audienceData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-audience-form')
            this.$emit('update:shall-show-audience-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeAudienceForm === 'Delete') {
        store.dispatch('audience-store/deleteAudience', {
          id: this.audienceData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-audience-form')
            this.$emit('update:shall-show-audience-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-audience-form')
      this.$emit('update:shall-show-audience-form-modal', false)
    },
    onSelectAudienceType(type) {
      this.audienceData.type = type
      if (this.typeAudienceForm === 'Add') {
        this.defaultName()
      }

      this.audienceData.specialty = ''
      this.audienceData.json = ''
      this.sheet = ''
      this.sheetOptions = null
    },
    defaultName() {
      const currentdate = new Date()
      const year = currentdate.getFullYear()
      let month = currentdate.getMonth() + 1
      let date = currentdate.getDate()
      let hour = currentdate.getHours()
      let minute = currentdate.getMinutes()

      if (month.toString().length === 1) {
        month = `0${month}`
      }
      if (date.toString().length === 1) {
        date = `0${date}`
      }
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
      if (minute.toString().length === 1) {
        minute = `0${minute}`
      }
      const datetime = `${year}${month}${date}${hour}${minute}`
      this.audienceData.name = `${this.audienceData.type} ${datetime}`
    },
    readerData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, {
            type: 'buffer',
            sheetStubs: true,
          })
          // const firstSheetName = workbook.SheetNames[0]
          const selectSheetName = this.sheet
          const worksheet = workbook.Sheets[selectSheetName]
          let option = {}
          option = {
            defval: '', raw: false, header: 1, blankrows: false,
          }
          const results = XLSX.utils
            .sheet_to_json(worksheet, option)
          // console.log(results)
          const reResults = []
          results.forEach(field => {
            reResults.push(field[0])
          })
          // console.log(reResults)
          this.audienceData.json = reResults
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    readerArrayData(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, {
            type: 'buffer',
            sheetStubs: true,
          })
          const reResults = []
          let no = 0
          workbook.SheetNames.forEach(selectSheetName => {
            const worksheet = workbook.Sheets[selectSheetName]
            let option = {}
            if (this.rowHeader === true) {
              option = {
                defval: '', raw: false,
              }
            } else {
              option = {
                defval: '', raw: false, header: 1, blankrows: false,
              }
            }
            const results = XLSX.utils
              .sheet_to_json(worksheet, option)
            results.forEach(field => {
              let fd = {}
              no += 1
              if (this.rowHeader === true) {
                fd = field
                fd.no = no
                fd.statusVariant = 'warning'
                fd.status = this.$i18n.t('Wait Import')
              } else {
                fd = {
                  prefix: field[0],
                  name: field[1],
                  lastname: field[2],
                  province: field[3],
                  license: '',
                  no,
                  statusVariant: 'warning',
                  status: this.$i18n.t('Wait Import'),
                }
              }
              reResults.push(fd)
              if (this.count >= 10) {
                this.count = 0
              }
              this.count += 1
              resolve()
            })
          })
          // console.log(reResults)
          this.tableDataImport = reResults

          this.importCountTotal = 0
          this.importCountOk = 0
          this.importCountError = 0

          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.showTable = false
          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Process'),
            text: this.$i18n.t('Your data has been {action}', { action: this.$i18n.t('Process') }),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    loadSheetOption(rawFile) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, {
            type: 'buffer',
            sheetStubs: true,
          })
          const sOptions = []
          workbook.SheetNames.forEach(sname => {
            sOptions.push({
              label: sname,
              value: sname,
            })
          })
          this.sheetOptions = sOptions
          if (this.sheetOptions.length > 0) {
            this.sheet = this.sheetOptions[0].value
            this.readerData(rawFile)
          }
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    changeFileImport(e) {
      const { files } = e.target
      const rawFile = files[0]
      this.rawFileImport = null
      if (!rawFile) return
      this.rawFileImport = rawFile
      this.loadSheetOption(this.rawFileImport)
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
