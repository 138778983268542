<template>
  <div
    v-if="$can('read', 'Audience')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'Audience')"
                      variant="primary"
                      style="width: 100%; max-width: 180px;"
                      @click="addAudienceForm"
                    >
                      {{ `${$t('Add')} ${$t('Audience')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalAudiences"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

        </div>
        <b-table
          ref="refAudienceListTable"
          :items="getListAudience"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <!-- Column: ID -->
          <template #cell(id)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="HashIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">{{ `${data.item.type}` }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">{{ `${data.item.name}` }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Data -->
          <template #cell(json)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">{{ $t('Quanlity') }}</span>
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ `${data.item.json.length}` }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">

              <a
                class="action-icon"
                @click.stop="editAudienceForm(data)"
              >
                <feather-icon
                  v-if="$can('edit', 'Audience')"
                  :id="`audience-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('edit', 'Audience')"
                  :title="`${$t('Edit')} ${$t('Audience')}`"
                  :target="`audience-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>
              <a
                class="action-icon"
                @click.stop="deleteAudienceForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'Audience')"
                  :id="`audience-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('delete', 'Audience')"
                  :title="`${$t('Delete')} ${$t('Audience')}`"
                  :target="`audience-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'Audience')"
                      variant="primary"
                      style="width: 100%; max-width: 180px;"
                      @click="addAudienceForm"
                    >
                      {{ `${$t('Add')} ${$t('Audience')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalAudiences"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <audience-form
        v-model="shallShowAudienceFormModal"
        :audience-data="audienceData"
        :type-audience-form="typeAudienceForm"
        @update-audience-form="updateAudienceForm"
        @discard-audience-form="discardAudienceForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import useAudienceList from './useAudienceList'
import audienceStoreModule from './audienceStoreModule'
import AudienceForm from './AudienceForm.vue'

export default {
  components: {
    AudienceForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      defaultData: {},
      audienceData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      collapsedFilter: true,
      typeOptions: [],
    }
  },
  mounted() {
    this.locale = this.$i18n.locale
    this.getDefaultAudience()
  },
  methods: {
    getDefaultAudience() {
      this.showOverlay = true
      store
        .dispatch('audience-store/getDefaultAudience', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.audienceData = this.defaultData
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addAudienceForm() {
      this.showOverlay = true
      store
        .dispatch('audience-store/getDefaultAudience', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.audienceData = response.data
          this.typeAudienceForm = 'Add'
          this.shallShowAudienceFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editAudienceForm(data) {
      this.showOverlay = true
      store
        .dispatch('audience-store/getAudience', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.audienceData = response.data
          this.typeAudienceForm = 'Edit'
          this.shallShowAudienceFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteAudienceForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('audience-store/deleteAudience', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateAudienceForm() {
      this.refetchData()
    },
    discardAudienceForm() {
      this.refetchData()
    },
    onCopy(data) {
      const copyText = data
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
  },
  setup() {
    const shallShowAudienceFormModal = ref(false)
    const typeAudienceForm = ref('')

    if (!store.hasModule('audience-store')) store.registerModule('audience-store', audienceStoreModule)
    onUnmounted(() => {
      if (store.hasModule('audience-store')) store.unregisterModule('audience-store')
    })

    const {
      getListAudience,
      tableColumns,
      perPage,
      currentPage,
      totalAudiences,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refAudienceListTable,
      refetchData,
    } = useAudienceList()

    return {
      shallShowAudienceFormModal,
      typeAudienceForm,

      getListAudience,
      tableColumns,
      perPage,
      currentPage,
      totalAudiences,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refAudienceListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/lineapi/audience.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #EB008B !important;
  }
}
</style>

<style lang="scss" scope>
@import '@core/scss/base/bootstrap-extended/include';
.media-heading {
  color: $body-color;
  margin-bottom: 0;
  line-height: 1.2;
}
</style>

<style lang="scss">
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
